import React, {useEffect, useState} from 'react';
import {getAdvancedReporting} from "../global/api";
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {PpeReportTemplate, PpeScheduledReport} from "../gen";
import {Link} from "react-router-dom";

import cronstrue from 'cronstrue';
import moment from "moment";

interface Props extends IGlobalDataContext {
    onSave: () => void;
}

function _NewScheduledReportForm(props: Props) {
    const [name, setName] = useState<string>("");
    const [emailSubject, setEmailSubject] = useState("");
    const [schedule, setSchedule] = useState("");
    const [recipients, setRecipients] = useState("");
    const [cutoff, setCutoff] = useState("day");

    const [template, setTemplate] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const [templates, setTemplates] = useState<PpeReportTemplate[]>([]);

    const loadTemplate = () => {
        setLoading(true);
        getAdvancedReporting().advancedReportingServiceListTemplates({}).then((response) => {
            setTemplates(response.data.templates ?? []);
        }).catch((e) => {
            window.alert(`Failed to load templates: ${e}`);
        }).finally(() => {
            setLoading(false);
        });
    }

    React.useEffect(() => {
        loadTemplate();
    }, []);

    const create = () => {
        console.log('create');

        if (!template) {
            window.alert("Please select a template")
            return;
        }

        getAdvancedReporting().advancedReportingServiceCreateScheduledReport({
            name: name,
            email_subject: emailSubject,
            cron: schedule,
            emails: recipients.split(',').map((email) => email.trim()),
            template_uuid: template,

            timezone: props.timezone,

            day: cutoff == "day",
            seven_days: cutoff == "seven_days",
            sunday_week: cutoff == "sunday_week",
            monday_week: cutoff == "monday_week",
            last_28_days: cutoff == "last_28_days",
            last_calendar_month: cutoff == "last_calendar_month",
        }).then((response) => {
            console.log(`response`, response);
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            props.onSave();
        })
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return <div className="container">
        <div className="row">
            <div className="col">
                <form className="form" onSubmit={(e) => {
                    create();
                    e.preventDefault()
                }}>
                    <div className={"form-group"}>
                        <label>Name</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Email Subject</label>
                        <input type="text" className="form-control" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Schedule</label>
                        <input type="text" className="form-control" value={schedule} onChange={(e) => setSchedule(e.target.value)}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Recipients</label>
                        <input type="text" className="form-control" value={recipients} onChange={(e) => setRecipients(e.target.value)}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Template</label>
                        <select className="form-control" value={template} onChange={(e) => setTemplate(e.target.value)}>
                            <option value={""}>Please select template</option>
                            {templates.map((template) => <option key={template.uuid} value={template.uuid}>{template.name}</option>)}
                        </select>
                    </div>

                    <div className={"form-group"}>
                        <label>Cutoff</label>
                        <select className="form-control" value={cutoff} onChange={(e) => setCutoff(e.target.value)}>
                            <option value="day">Last Day</option>
                            <option value="seven_days">Seven Days</option>
                            <option value="sunday_week">Sunday Week</option>
                            <option value="monday_week">Monday Week</option>
                            <option value="last_28_days">Last 28 Days</option>
                            <option value="last_calendar_month">Last Calendar Month</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

const NewScheduledReportForm = withGlobalData(_NewScheduledReportForm);

function ReportRow(props: { deleteReport: (uuid: string) => void, report: PpeScheduledReport, templates: PpeReportTemplate[] }) {
    const report = props.report;
    const templates = props.templates

    const [running, setRunning] = useState(false);

    let explain = "-";

    console.log(report.template_uuid);
    console.log(templates);

    const template = templates.find((template) => template.uuid == report.template_uuid)?.name ?? "-";

    try {
        explain = cronstrue.toString(report.cron ?? "", {verbose: true});
    } catch (e) {
        console.error(e);
        explain = "Invalid format";
    }

    function runScheduledReport() {
        setRunning(true);

        getAdvancedReporting().advancedReportingServiceManualExecuteScheduledReport({
            scheduled_report_uuid: report.uuid ?? ""
        }).then(() => {
            window.alert("Report is scheduled successfully")
        }).catch((e) => {
            window.alert(`Failed to run report: ${e}`);
        }).finally(() => {
            setRunning(false);
        });
    }

    return <tr key={report.uuid}>
        <td>{report.name}</td>
        <td>
            {report.email_subject}
            <br/>
            {template}
        </td>
        <td>
            <pre>{report.cron}</pre>
            <br/>Explanation: {explain}
            <br/>Timezone: {report.timezone}
        </td>
        <td>{(report.emails ?? []).join(', ')}</td>
        <td>{(moment(report.created_at).toISOString())}</td>
        <td>-</td>
        <td>-</td>
        <td>
            <button onClick={(e) => {
                e.preventDefault();
                if (!window.confirm("Are you sure?")) {
                    return;
                }
                props.deleteReport(report.uuid ?? "");
            }} className="btn btn-danger">Delete
            </button>
            |
            <button disabled={running} onClick={(e) => {
                e.preventDefault();
                runScheduledReport();
            }} className="btn btn-primary">Run</button>
        </td>
    </tr>
}

function _ScheduledReports() {
    const [busy, setBusy] = React.useState<boolean>(false);
    const [newReportFormVisible, setNewReportFormVisible] = React.useState<boolean>(false);
    const [reports, setReports] = React.useState<PpeScheduledReport[]>([])
    const [templates, setTemplates] = React.useState<PpeReportTemplate[]>([])

    const toggleNewReportForm = () => {
        setNewReportFormVisible(!newReportFormVisible)
    }

    const refresh = () => {
        setBusy(true);
        setNewReportFormVisible(false);

        getAdvancedReporting().advancedReportingServiceListScheduledReports({}).then((response) => {
            setReports(response.data.reports ?? [])
        }).catch((e) => {
            window.alert(`Failed to load scheduled reports: ${e}`);
        }).finally(() => {
            setBusy(false);
        })


        getAdvancedReporting().advancedReportingServiceListTemplates({}).then((response) => {
            setTemplates(response.data.templates ?? []);
        }).catch((e) => {
            window.alert(`Failed to load templates: ${e}`);
        }).finally(() => {
        })
    }

    useEffect(() => {
        refresh();
    }, []);

    if (busy) {
        return <div id="content-page" className="content-page"><h1>Loading</h1></div>
    }

    const deleteReport = (uuid: string) => {
        getAdvancedReporting().advancedReportingServiceRemoveScheduledReport({
            scheduled_report_uuid: uuid
        }).then(() => {
            refresh();
        }).catch((e) => {
            window.alert(`Failed to delete report: ${e}`);
        }).finally(() => {
        });
    }

    const rows = reports.map((report) => {
        return <ReportRow key={report.uuid} report={report} deleteReport={deleteReport} templates={templates}/>
    });

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>Scheduled Reports</title>
        </Helmet>
        <div className="container-fluid">
            <div className={"iq-card"}>
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Scheduled Reports</h4>
                    </div>


                    <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link to={"/scheduled-reports/templates"} className="btn btn-info">Manage Report Templates</Link>
                        &nbsp; &nbsp;
                        <button onClick={toggleNewReportForm} className="btn btn-primary">New Report</button>
                    </div>
                </div>
                <div className="iq-card-body">

                    <div className={"alert alert-danger"}>
                        <b>Note &nbsp;</b>

                        Advanced reporting feature is currently available for feedback and evaluation. It's still in Work in Progress stage, and it's subject to changes without notice. It will be available to all organizations in Advanced Plan after the evaluation period.
                    </div>


                    {newReportFormVisible && <NewScheduledReportForm onSave={refresh}/>}

                    <table className="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email Subject</th>
                            <th>Schedule</th>
                            <th>Recipients</th>
                            <th>Created Time</th>
                            <th>Last Running Time</th>
                            <th>Cutoff</th>
                            <th>Operation</th>
                        </tr>
                        </thead>
                        {rows}
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export const ScheduledReports = withGlobalData(_ScheduledReports)
