import React, {Component} from 'react';
import Modal from "react-modal";
import {getAdminProductServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {Ppeemployerv1ProductInfo, V1AdminTag} from "../gen/typescript-axios";
import IconWithTooltip from "../icon_with_tooltip";
import { hasSpecialCharacters } from '../has_special_characters';
import CustomSelectDropdown from '../custom_select_dropdown/custom_select_dropdown';

interface Props {
    isOpen: boolean

    closeEditProductModal(): void

    reloadAllProducts(): void

    pushFlashMessage(message: Message): void;
    productInfo: Ppeemployerv1ProductInfo
}

interface State {
    newProductName: string
    pictureEncodedContent: string
    newTrackable: boolean,
    isRequireInspectionAfterSeveralUses: boolean
    isRemovePhoto: boolean
    newInspectionInterval: number

    manufacturer: string
    manufacturerCode: string
    supplier: string
    supplierCode: string
    selectedTags: V1AdminTag[],
}

class EditProductModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newTrackable: this.props.productInfo.is_trackable ?? false,
            newProductName: this.props.productInfo.name ?? "",
            pictureEncodedContent: "",
            isRequireInspectionAfterSeveralUses: (this.props.productInfo.inspection_interval ?? 0) !== 0,
            isRemovePhoto: false,
            newInspectionInterval: this.props.productInfo.inspection_interval ?? 0,

            manufacturer: this.props.productInfo.manufacturer ?? "", 
            manufacturerCode: this.props.productInfo.manufacturer_code ?? "", 
            supplier:this.props.productInfo.supplier ?? "",
            supplierCode: this.props.productInfo.supplier_code ?? "",
            selectedTags: this.props.productInfo.tags ?? [],
        };
    }


    onSelectFile(e: any) {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.setState({pictureEncodedContent: window.btoa(pictureContent)})
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    handleCloseModal() {
        this.props.closeEditProductModal();
        this.setState({
            newTrackable: this.props.productInfo.is_trackable ?? false,
            newProductName: this.props.productInfo.name ?? "",
            pictureEncodedContent: "",
            isRequireInspectionAfterSeveralUses: this.props.productInfo.inspection_interval !== 0,
            isRemovePhoto: false,
            newInspectionInterval: this.props.productInfo.inspection_interval ?? 0,
            selectedTags: this.props.productInfo.tags ?? [],
        })
    }

    handleProductMapChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({newProductName: e.target.value})
    }

    handleManufacturerChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({manufacturer: e.target.value})
    }

    handleManufacturerCodeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({manufacturerCode: e.target.value})
    }

    handleSupplierChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({supplier: e.target.value})
    }

    handleSupplierCodeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({supplierCode: e.target.value})
    }

    handleInspectionIntervalChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value === '0'){
            this.setState({isRequireInspectionAfterSeveralUses: false})
        }
        this.setState({newInspectionInterval: e.target.value})
    }

    handleTrackableChange(e: any) {
        console.log("Checked: " + e.target.checked);
        if (!e.target.checked){
            this.setState({isRequireInspectionAfterSeveralUses: false, newInspectionInterval: 0});
        }
        this.setState({newTrackable: e.target.checked});
    }

    handleIsRequireInspectionAfterSeveralUsesChange(e: any) {
        console.log("Checked: " + e.target.checked);
        if (!e.target.checked){
            this.setState({newInspectionInterval: 0});
        } else {
            this.setState({newInspectionInterval: 1});
        }
        this.setState({isRequireInspectionAfterSeveralUses: e.target.checked});
    }

    handleSelectionChange(newSelectedTags: V1AdminTag[]) {
        if (newSelectedTags !== this.state.selectedTags) {
            this.setState({ selectedTags: [...newSelectedTags] });
        }
    }

    updateProduct(e: any) {
        e.preventDefault();
        e.stopPropagation();

        if (hasSpecialCharacters(this.state.newProductName, this.state.manufacturer, this.state.manufacturerCode, this.state.supplier, this.state.supplierCode)) {
            this.props.pushFlashMessage(new Message("Special characters are not allowed", "error"));
            return;
        }

        let product_photo: string | null = null

        if (this.state.pictureEncodedContent !== "" && this.state.pictureEncodedContent != null) {
            product_photo = this.state.pictureEncodedContent;
        }

        getAdminProductServiceAPI().adminProductServiceUpdateProduct(this.props.productInfo.uuid ?? "", {
            name: this.state.newProductName,
            photo:product_photo ?? undefined,
            trackable: this.state.newTrackable,
            inspection_interval: this.state.newInspectionInterval,
            remove_photo: this.state.isRemovePhoto,
            manufacturer: this.state.manufacturer,
            manufacturer_code: this.state.manufacturerCode,
            supplier: this.state.supplier,
            supplier_code: this.state.supplierCode,
            tag_uuids: (
                this.state.selectedTags.length > 0
                ? this.state.selectedTags.map((tag) => tag.uuid).filter((uuid): uuid is string => uuid !== undefined)
                : []
              )
        }).then((response)=>{
            if (response.data.success) {
                this.handleCloseModal()
                this.props.reloadAllProducts();
                this.props.pushFlashMessage(new Message("Product Updated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(()=>{
            this.props.pushFlashMessage(new Message("Server error, please try again later", "error"))
        })
    }

    removePhoto(e: any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isRemovePhoto: true
        })
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Edit Product</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please edit product</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Product Name:*</label>
                        <input type="text" onChange={this.handleProductMapChange.bind(this)} className="form-control"
                               value={this.state.newProductName} required/>
                    </div>
                    <div className="form-group">
                        <label>Product Picture (optional)</label>
                        {!this.state.isRemovePhoto && this.props.productInfo.photo_url !== null && <div>
                            <img src={this.props.productInfo.photo_url} alt={"Picture"} width={"80"} />
                            <button type="button" className="btn iq-bg-danger"
                                    onClick={this.removePhoto.bind(this)}>Remove
                            </button>
                        </div>}
                        <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"}/>
                    </div>
                    <div className={"form-group"}>
                        <label>Trackable
                            <IconWithTooltip
                                bgColor={""}
                                icon={<i className="las la-info-circle"></i>}
                                tooltipText={"Trackable Item is used in conjunction with returnable lockers."}
                                tooltipPlacement={"right"}/>
                            &nbsp;&nbsp;
                            <input type="checkbox" checked={this.state.newTrackable}
                                   onChange={this.handleTrackableChange.bind(this)}/>
                        </label>
                    </div>
                    <hr />
                    <div className="form-group">
                        <label htmlFor="name">Manufacturer:</label>
                        <input type="text" onChange={this.handleManufacturerChange.bind(this)} className="form-control"
                               value={this.state.manufacturer} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Manufacturer Code:</label>
                        <input type="text" onChange={this.handleManufacturerCodeChange.bind(this)} className="form-control"
                               value={this.state.manufacturerCode} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Supplier:</label>
                        <input type="text" onChange={this.handleSupplierChange.bind(this)} className="form-control"
                               value={this.state.supplier} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Supplier Code:</label>
                        <input type="text" onChange={this.handleSupplierCodeChange.bind(this)} className="form-control"
                               value={this.state.supplierCode} required/>
                    </div>

                    {this.state.newTrackable &&
                        <div className={"form-group"}>
                            <label>Require inspection after use</label>
                            &nbsp;&nbsp;
                            <input type="checkbox" checked={this.state.isRequireInspectionAfterSeveralUses}
                                   onChange={this.handleIsRequireInspectionAfterSeveralUsesChange.bind(this)}/>
                        </div>
                    }

                    {this.state.isRequireInspectionAfterSeveralUses &&
                        <div className={"form-group"}>
                            <label>Inspection Interval</label>
                            &nbsp;&nbsp;
                            <input type="number" onChange={this.handleInspectionIntervalChange.bind(this)} className="form-control"
                                   value={this.state.newInspectionInterval}/>
                        </div>
                    }

                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="email">Access Group:</label>*/}
                    {/*    <select onChange={this.handleProductCategoryChange.bind(this)} className="form-control" value={this.state.newCategory !== undefined ? this.state.newCategory.uuid : ""}>*/}
                    {/*        <option value="">No Access Group</option>*/}
                    {/*        {allCategories}*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <div className="form-group">
                        <label htmlFor="tag">Tags:</label>
                        <CustomSelectDropdown selectedTags={this.state.selectedTags} onSelectionChange={this.handleSelectionChange.bind(this)} pushFlashMessage={this.props.pushFlashMessage} />
                    </div>
                    <button onClick={this.updateProduct.bind(this)} className="btn btn-primary">Save</button>
                    &nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.handleCloseModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {EditProductModal}
