import React from 'react';
// import {PpeTransaction} from "../gen";
import TransactionRow from "./transaction_row";
import {Ppeemployerv1Transaction} from "../gen/typescript-axios";

interface Props{
    transactions:Ppeemployerv1Transaction[]
    timezone: string
    reload():void
}

function TransactionTable(props:Props) {
    const rows = props.transactions.map((transaction)=>{
        return <TransactionRow transaction={transaction} timezone={props.timezone} key={transaction.uuid} reload={props.reload}/>
    })
    return (
        <div>
            <table className="table mb-0">
                <thead className="thead-light">
                <tr>
                    <th scope="col">UUID</th>
                    <th scope="col">Employee</th>
                    <th scope="col">Key Card Number</th>
                    <th scope="col">Time</th>
                    <th scope="col">Product</th>
                    <th scope="col">Vending Machine/Locker</th>
                    <th scope="col">Location</th>
                    <th scope="col">Job ID</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    );
}

export default TransactionTable;
