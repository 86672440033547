import React, { Component } from 'react';
import Modal from "react-modal";
import {getAdminProductServiceAPI} from "../global/api";
import {Message} from "../global/models";
import IconWithTooltip from "../icon_with_tooltip";
import { hasSpecialCharacters } from '../has_special_characters';
import { V1AdminTag } from '../gen/typescript-axios';
import CustomSelectDropdown from '../custom_select_dropdown/custom_select_dropdown';

interface Props {
    isOpen: boolean
    closeAddProductModal():void
    reloadProduct():void
    pushFlashMessage(message: Message): void;
}

interface State {
    newCategoryUUID:string|null
    newProductName:string,
    pictureEncodedContent:string
    newTrackable: boolean,
    isRequireInspectionAfterSeveralUses: boolean
    newInspectionInterval: number

    manufacturer: string
    manufacturerCode: string
    supplier: string
    supplierCode: string
    selectedTags: V1AdminTag[]
}

class AddProductModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newCategoryUUID:null,
            newProductName: "",
            pictureEncodedContent:"",
            newTrackable: false,
            isRequireInspectionAfterSeveralUses:false,
            newInspectionInterval: 0,
            manufacturer: "", 
            manufacturerCode: "", 
            supplier: "",
            supplierCode: "",
            selectedTags: []
        };
    }

    onClose(){
        this.setState({
            newProductName:"",
            newTrackable: false,
            newInspectionInterval: 0,
            isRequireInspectionAfterSeveralUses: false,
            selectedTags: [],
        })
        this.props.closeAddProductModal();
    }

    saveNewProduct(event:any){
        event.preventDefault();
        event.stopPropagation();

        let data;

        if (hasSpecialCharacters(this.state.newProductName, this.state.manufacturer, this.state.manufacturerCode, this.state.supplier, this.state.supplierCode)) {
            this.props.pushFlashMessage(new Message("Special characters are not allowed", "error"));
            return;
        }
        

        if (this.state.pictureEncodedContent !== "" && this.state.pictureEncodedContent!= null){
            data = {
                product_name: this.state.newProductName,
                product_photo: this.state.pictureEncodedContent,
                is_trackable: this.state.newTrackable,
                inspection_interval: this.state.newInspectionInterval,
                manufacturer: this.state.manufacturer,
                manufacturer_code: this.state.manufacturerCode,
                supplier: this.state.supplier,
                supplier_code: this.state.supplierCode,
                tag_uuids: (
                    this.state.selectedTags.length > 0
                    ? this.state.selectedTags.map((tag) => tag.uuid).filter((uuid): uuid is string => uuid !== undefined)
                    : []
                  )
            }
        } else {
            data = {
                product_name: this.state.newProductName,
                is_trackable: this.state.newTrackable,
                inspection_interval: this.state.newInspectionInterval,
                manufacturer: this.state.manufacturer,
                manufacturer_code: this.state.manufacturerCode,
                supplier: this.state.supplier,
                supplier_code: this.state.supplierCode,
                tag_uuids: (
                    this.state.selectedTags.length > 0
                    ? this.state.selectedTags.map((tag) => tag.uuid).filter((uuid): uuid is string => uuid !== undefined)
                    : []
                  )
            }
        }
        console.log(data)

        

        getAdminProductServiceAPI().adminProductServiceAddProduct(data).then(response => {
            if (response.data.success) {
                this.onClose()
                this.props.reloadProduct();
                this.props.pushFlashMessage(new Message("Product Added", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }

    handleNewProductNameChange(event:any){
        this.setState({newProductName:event.target.value})
    }

    handleCategoryChange(event:any){
        this.setState({newCategoryUUID:event.target.value})
    }

    onSelectFile(e:any) {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.setState({pictureEncodedContent:window.btoa(pictureContent)})
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    handleInspectionIntervalChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value === '0'){
            this.setState({isRequireInspectionAfterSeveralUses: false})
        }
        this.setState({newInspectionInterval: e.target.value})
    }

    handleTrackableChange(e: any) {
        console.log("Checked: " + e.target.checked);
        if (!e.target.checked){
            this.setState({isRequireInspectionAfterSeveralUses: false, newInspectionInterval: 0});
        }
        this.setState({newTrackable: e.target.checked});
    }

    handleIsRequireInspectionAfterSeveralUsesChange(e: any) {
        console.log("Checked: " + e.target.checked);
        if (!e.target.checked){
            this.setState({newInspectionInterval: 0});
        } else {
            this.setState({newInspectionInterval: 1});
        }
        this.setState({isRequireInspectionAfterSeveralUses: e.target.checked});
    }

    handleManufacturerChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({manufacturer: e.target.value})
    }

    handleManufacturerCodeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({manufacturerCode: e.target.value})
    }

    handleSupplierChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({supplier: e.target.value})
    }

    handleSupplierCodeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({supplierCode: e.target.value})
    }

    handleSelectionChange(newSelectedTags: V1AdminTag[]) {
        if (newSelectedTags !== this.state.selectedTags) {
            this.setState({ selectedTags: [...newSelectedTags] });
        }
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={()=>{this.onClose()}}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">New Product</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please enter the detail of new product</p>
                <form onSubmit={this.saveNewProduct.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="name">Product Name:*</label>
                        <input type="text" onChange={this.handleNewProductNameChange.bind(this)} className="form-control" id="name" name="name" value={this.state.newProductName} required/>
                    </div>
                    <div className="form-group">
                        <label>Product Picture (optional)</label>
                        <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"}/>
                    </div>
                    <div className={"form-group"}>
                        <label>Trackable
                            <IconWithTooltip
                                bgColor={""}
                                icon={<i className="las la-info-circle"></i>}
                                tooltipText={"Trackable Item is used in conjunction with returnable lockers."}
                                tooltipPlacement={"right"}/>
                            &nbsp;&nbsp;
                            <input type="checkbox" checked={this.state.newTrackable}
                                   onChange={this.handleTrackableChange.bind(this)}/>
                        </label>
                    </div>
                    <hr />
                    <div className="form-group">
                        <label htmlFor="name">Manufacturer:</label>
                        <input type="text" onChange={this.handleManufacturerChange.bind(this)} className="form-control"
                               value={this.state.manufacturer} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Manufacturer Code:</label>
                        <input type="text" onChange={this.handleManufacturerCodeChange.bind(this)} className="form-control"
                               value={this.state.manufacturerCode} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Supplier:</label>
                        <input type="text" onChange={this.handleSupplierChange.bind(this)} className="form-control"
                               value={this.state.supplier} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Supplier Code:</label>
                        <input type="text" onChange={this.handleSupplierCodeChange.bind(this)} className="form-control"
                               value={this.state.supplierCode} required/>
                    </div>

                    {this.state.newTrackable &&
                        <div className={"form-group"}>
                            <label>Require inspection after use</label>
                            &nbsp;&nbsp;
                            <input type="checkbox" checked={this.state.isRequireInspectionAfterSeveralUses}
                                   onChange={this.handleIsRequireInspectionAfterSeveralUsesChange.bind(this)}/>
                        </div>
                    }

                    {this.state.isRequireInspectionAfterSeveralUses &&
                        <div className={"form-group"}>
                            <label>Inspection Interval</label>
                            &nbsp;&nbsp;
                            <input type="number" onChange={this.handleInspectionIntervalChange.bind(this)} className="form-control"
                                   value={this.state.newInspectionInterval}/>
                        </div>
                    }

                    <div className="form-group">
                        <label htmlFor="tag">Tags:</label>
                        <CustomSelectDropdown selectedTags={this.state.selectedTags} onSelectionChange={this.handleSelectionChange.bind(this)} pushFlashMessage={this.props.pushFlashMessage} />
                    </div>
                    <button type="submit" className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                            onClick={()=>{this.onClose()}}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {AddProductModal}
