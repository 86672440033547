import { Component } from 'react';
import Modal from "react-modal";
import { Message } from "../global/models";
import "react-datepicker/dist/react-datepicker.css";
import {V1ImportProductInfo} from "../gen/typescript-axios";
import {getAdminProductServiceAPI} from "../global/api";
import { hasSpecialCharacters } from '../has_special_characters';

interface Props {
    isOpen: boolean
    closeUploadProductCSVkModal(): void
    reloadProduct(): void
    pushFlashMessage(message: Message): void;
    csvData: V1ImportProductInfo[];
}

interface State {
}

class UploadProductCSVModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        console.log('csvData', this.props.csvData);
    }

    closeUploadProductCSVModal() {
        this.props.closeUploadProductCSVkModal()
    }


    parseProduct(raw: V1ImportProductInfo): V1ImportProductInfo {
        return {
            uuid: raw.uuid?.trim(),
            name: raw.name?.trim(),
            manufacturer: raw.manufacturer?.trim(),
            manufacturer_code: raw.manufacturer_code?.trim(),
            supplier: raw.supplier?.trim(),
            supplier_code: raw.supplier_code?.trim(),
            inspection_interval: raw.inspection_interval,
            trackable: raw.trackable,
        }
      }


    updateProductMapCSV(e: any) {
        e.preventDefault();
        e.stopPropagation();

        const products =  this.props.csvData.map((product)=>{
            return this.parseProduct(product);
        })

        for (let product of products) {
            if (hasSpecialCharacters(product.name ?? '', product.manufacturer ?? '', product.manufacturer_code ?? '', product.supplier ?? '', product.supplier_code ?? '')) {
                this.props.pushFlashMessage(new Message('Product name, manufacturer, manufacturer code, supplier, or supplier code contains special characters. Please remove them and try again.','error'));
                return;
            }
        }
    
        getAdminProductServiceAPI().adminProductServiceImportProducts({
            products: products
        }).then((response)=>{
            if (response.data.success){
                this.closeUploadProductCSVModal();
                this.props.reloadProduct();
                this.props.pushFlashMessage(new Message("ImportProductMaps", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                this.closeUploadProductCSVModal();
            }
        }).catch(()=>{
            this.props.pushFlashMessage(new Message("Server error", "error"))
            this.closeUploadProductCSVModal();
        })
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeUploadProductCSVModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Batch Import Product Map</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group"
                         style={{ overflow: 'auto', maxHeight: '1000px' }}>
                        <label>Please Check the Product Map</label>
                        <table  id="datatable"
                                className="table table-striped table-bordered mt-4 text-center">
                            <thead>
                                <tr>
                                    <th>Uuid</th>
                                    <th>Name</th>
                                    <th>Manufacturer</th>
                                    <th>Manufacturer Code</th>
                                    <th>Supplier</th>
                                    <th>Supplier Code</th>
                                    <th>Inspection Interval</th>
                                    <th>Is Trackable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.csvData.map((product,index)=>{
                                       return   <tr key={product.uuid}>
                                                    <td>{product.uuid}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.manufacturer}</td>
                                                    <td>{product.manufacturer_code}</td>
                                                    <td>{product.supplier}</td>
                                                    <td>{product.supplier_code}</td>
                                                    <td>{product.inspection_interval}</td>
                                                    <td>{product.trackable ? 'true' : 'false'}</td>
                                                </tr> ;                          
                                    })         
                                }
                            </tbody>
                        </table>
                    </div>
                    <button onClick={this.updateProductMapCSV.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                        onClick={this.closeUploadProductCSVModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export { UploadProductCSVModal };
