import React, { Component} from 'react';
import {CSVLink} from "react-csv";
import moment from "moment";
import {Ppeemployerv1ProductInfo} from "../gen/typescript-axios";

interface Props {
    allProduct:Ppeemployerv1ProductInfo[]
}

interface State {
    csvData: Ppeemployerv1ProductInfo[]
    ready:boolean
}

class DownloadProductCsv extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            csvData: [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    getData(){
        this.csvLink.current.link.click();
    }

    prepareCsvHeader(){
        return [
            { label: 'Uuid', key: 'uuid' },
            { label: 'Name', key: 'name' },
            { label: 'Manufacturer', key: 'manufacturer' },
            { label: 'Manufacturer Code', key: 'manufacturer_code' },
            { label: 'Supplier', key: 'supplier' },
            { label: 'Supplier Code', key: 'supplier_code' },
            { label: 'Inspection Interval', key: 'inspection_interval' },
            { label: 'Is Trackable', key: 'is_trackable' },
        ]
    }

    prepareCsvData(){
        let csvData:{}[] = [];
        this.props.allProduct.forEach((product)=>{
            csvData.push({
                uuid: product.uuid,
                name: product.name,
                manufacturer: product.manufacturer,
                manufacturer_code: product.manufacturer_code,
                supplier: product.supplier,
                supplier_code: product.supplier_code,
                inspection_interval: product.inspection_interval,
                is_trackable: product.is_trackable,
            })
        })
        return csvData
    }

    render() {
        return <div>
            <button className="btn btn-primary btn-sm" onClick={this.getData.bind(this)}>Download as CSV</button>

            <CSVLink
                data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                filename={'product_'+moment().unix()+'_.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadProductCsv}
