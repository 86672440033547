import React from 'react';
import {Authentication, Message, VendingMachine} from "./models";
import {V1VendingMachineInfo} from "../gen/typescript-axios";
import {HidSettings} from "../hid";

export interface IGlobalDataContext {
    allVendingMachines: Array<V1VendingMachineInfo>;
    authentication:Authentication;
    globalTitle: string;
    company: string;
    hidInfo?: HidSettings;
    userName:string;
    timezone: string;

    reloadAllVendingMachines():void;
    reloadCompanyLogo():void;

    findVendingMachine(uuid:string):VendingMachine|undefined;
    pushFlashMessage(message: Message): void;
}

const GlobalDataContext = React.createContext({
    allVendingMachines:[],
    globalTitle: "",
    company: "",
    hidInfo: {
        facilityCode: "",
        facilityCodeLength: 0,
    },
    userName:"",
    timezone:"",
    authentication: {
        authenticated: null,
        email: null,
        authentication_token: null,
    },
    reloadAllVendingMachines:() => {},
    reloadCompanyLogo:() => {},
    findVendingMachine:(uuid:string)=>{},
    pushFlashMessage: (message: string, cls: string) => {},
});

function withGlobalData(C: any){
    return function dataComponent(props: any){
        return(
            <GlobalDataContext.Consumer>
                {({allVendingMachines,authentication,globalTitle, company, userName, timezone,hidInfo,
                      reloadAllVendingMachines, reloadCompanyLogo,
                      findVendingMachine, pushFlashMessage})=>{
                    return <C {...props}
                              allVendingMachines={allVendingMachines}
                              authentication={authentication}
                              globalTitle={globalTitle}
                              company={company}
                              userName={userName}
                              timezone={timezone}
                              reloadAllVendingMachines={reloadAllVendingMachines}
                              reloadCompanyLogo={reloadCompanyLogo}
                              findVendingMachine={findVendingMachine}
                              hidInfo={hidInfo}
                              pushFlashMessage={pushFlashMessage}/>
                }}
            </GlobalDataContext.Consumer>
        )
    }
}

export {GlobalDataContext, withGlobalData}
