import React, { Component } from 'react';
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
// import {PpeTransaction} from "../gen";

import {Ppeemployerv1Transaction} from "../gen/typescript-axios";
import TransactionTable from "../transaction_page/transaction_table";

interface Props extends IGlobalDataContext{
    recentTransactions:Ppeemployerv1Transaction[]
    reload():void
}

interface State {

}

class _LatestTransactions extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (<div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Latest Transactions</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <TransactionTable transactions={this.props.recentTransactions} timezone={this.props.timezone} reload={this.props.reload.bind(this)}/>
            </div>
        </div>);
    }
}

export const LatestTransactions = withGlobalData(_LatestTransactions);
