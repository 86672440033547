import React, {Component, Fragment} from 'react';
import ReactPaginate from "react-paginate";
import {
    getAdminCompanyInformationServiceAPI,
    getAdminLockerServiceApi,
    getAdminProductServiceAPI,
    getAdminTransactionServiceApi
} from "../global/api";
import {Aggregation, OrderBy, OrderSequence,} from "../global/models";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import moment from "moment";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";
import {DownloadTransactionsCsv} from "./download_transactions_csv";
import {DownloadAggregationCsv} from "./download_aggregation_csv";
import DatePicker from "react-datepicker";
import {TransactionChart} from "./transaction_chart";
import {
    PpeEmployee,
    PpeLockerInformation,
    PpeProductInfo,

    PpeTransactionsCount,
} from "../gen";
import {
    V1AggregatedByProductTransaction,
    V1AggregatedByEmployeeTransaction,
    V1AggregatedByMachineTransaction,
    V1TransactionAggregationOrder,
    V1Aggregation,
    V1TransactionAggregation,
    Ppeemployerv1Transaction
} from "../gen/typescript-axios";
import {getEndOfDay, getStartOfDay} from "../date_utils";
import TransactionRow from "./transaction_row";

interface Props extends IGlobalDataContext{
    location:any
    match:any
    history:any
}

interface State {
    transactions: Ppeemployerv1Transaction[]
    currentPage:number
    pageCount:number

    startDate:string
    endDate:string
    employeeUuid:string|null
    productUuid:string|null
    vendingMachineUuid:string
    lockerUuid:string
    aggregation:Aggregation
    numberOfTransactionsPerPage:number

    allLockers: PpeLockerInformation[]
    allEmployeeAggregatedTransactions: V1AggregatedByEmployeeTransaction[]
    allProductAggregatedTransactions: V1AggregatedByProductTransaction[]
    allMachineAggregatedTransactions: V1AggregatedByMachineTransaction[]
    aggOrderBy:OrderBy
    aggOrderSequence:OrderSequence

    transactionsCount: PpeTransactionsCount[]
    isChartLoading: boolean
    chartLoadingTimeStamp: number | null

    employeeOptions: PpeEmployee[]
    isEmployeeOptionsLoading: boolean
    selectedEmployee: PpeEmployee | null

    selectedProduct: PpeProductInfo | null
    productOptions: PpeProductInfo[]
    isproductOptionsLoading: boolean

    transactionChartAggregation: V1Aggregation,
    jobCode: string
}

class _TransactionPage extends Component<Props, State> {
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));

    state: State

    constructor(props: Props) {
        super(props);
        console.log("run constructor")
        this.state = {
            transactions:[],
            pageCount:0,
            startDate:this.params['start_date'] !== "" ? this.params['start_date'] !== undefined ? this.params['start_date'] : "" : "",
            endDate:this.params['end_date'] !== "" ? this.params['end_date'] !== undefined ? this.params['end_date'] : "" : "",
            employeeUuid:this.params['employee_uuid'] !== "" ? this.params['employee_uuid'] !== undefined ? this.params['employee_uuid'] : null : null,
            productUuid:this.params['product_uuid'] !== "" ? this.params['product_uuid'] !== undefined ? this.params['product_uuid'] : null : null,
            vendingMachineUuid:this.params['vending_machine_uuid'] !== "" ? this.params['vending_machine_uuid'] !== undefined ? this.params['vending_machine_uuid'] : "" : "",
            lockerUuid:this.params['locker_uuid'] !== "" ? this.params['locker_uuid'] !== undefined ? this.params['locker_uuid'] : "" : "",
            aggregation:this.params['aggregation'] !== "" ? this.params['aggregation'] !== undefined ? this.params['aggregation'] : Aggregation.NONE : Aggregation.NONE,
            numberOfTransactionsPerPage:this.params['trans_per_page'] !== "" ? this.params['trans_per_page'] !== undefined ? parseInt(this.params['trans_per_page']) : 25 : 25,
            currentPage:this.params['page'] !== "" ? this.params['page'] !== undefined ? parseInt(this.params['page'])-1 : 0 : 0,
            jobCode: this.params['job_code'] !== "" ? this.params['job_code'] !== undefined ? this.params['job_code'] : "" : "",

            allLockers:[],
            allEmployeeAggregatedTransactions:[],
            allProductAggregatedTransactions:[],
            allMachineAggregatedTransactions:[],
            aggOrderBy:OrderBy.COUNT,
            aggOrderSequence:OrderSequence.DESC,

            transactionsCount: [],
            isChartLoading:false,
            chartLoadingTimeStamp:null,

            employeeOptions:[],
            isEmployeeOptionsLoading:false,
            selectedEmployee: null,

            selectedProduct: null,

            productOptions: [],
            isproductOptionsLoading: false,


            transactionChartAggregation: V1Aggregation.Day
        };
    }

    componentDidMount() {
        this.getEmployeeByUuid()
        this.reloadTransaction()
        this.reloadAllLocker()
        this.getProductByUuid();
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            this.getParamsFromUrl();
        }
    }

    getEmployeeByUuid(){
        if (this.state.employeeUuid !== "" && this.state.employeeUuid !== null){
            getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceGetEmployeeDetails(this.state.employeeUuid).then((response)=>{
                if (response.data.success){
                    this.setState({
                        selectedEmployee:response.data.employee ?? null
                    })
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(() => {
                window.alert("Server error")
            })
        }
    }

    getProductByUuid(){
        if (this.state.productUuid !== "" && this.state.productUuid !== null){
            getAdminProductServiceAPI().adminProductServiceGetProduct(this.state.productUuid).then((response)=>{
                if (response.data.success){
                    this.setState({
                        selectedProduct:response.data.product ?? null
                    })
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(() => {
                window.alert("Server error")
            })
        }
    }

    getAggregateBy(){
        let aggregateBy = undefined;
        if (this.state.aggregation === Aggregation.EMPLOYEE){
            aggregateBy = V1TransactionAggregation.AggregatedByEmployee
        }
        if (this.state.aggregation === Aggregation.ITEM){
            aggregateBy = V1TransactionAggregation.AggregatedByProduct
        }
        if (this.state.aggregation === Aggregation.VENDINGMACHINE){
            aggregateBy = V1TransactionAggregation.AggregatedByMachine
        }
        return aggregateBy;
    }

    getOrderBy(){
        let orderBy = undefined;
        if (this.state.aggOrderBy === OrderBy.NAME && this.state.aggOrderSequence === OrderSequence.ASC){
            orderBy = V1TransactionAggregationOrder.Name
        }
        if (this.state.aggOrderBy === OrderBy.NAME && this.state.aggOrderSequence === OrderSequence.DESC){
            orderBy = V1TransactionAggregationOrder.NameDesc
        }
        if (this.state.aggOrderBy === OrderBy.COUNT && this.state.aggOrderSequence === OrderSequence.ASC){
            orderBy = V1TransactionAggregationOrder.Count
        }
        if (this.state.aggOrderBy === OrderBy.COUNT && this.state.aggOrderSequence === OrderSequence.DESC){
            orderBy = V1TransactionAggregationOrder.CountDesc
        }
        return orderBy;
    }

    getParamsFromUrl(){
        const params = this.qs.parse(this.props.location.search.slice(1));
        this.setState({
            startDate:params['start_date'] !== "" ? params['start_date'] !== undefined ? params['start_date'] : "" : "",
            endDate:params['end_date'] !== "" ? params['end_date'] !== undefined ? params['end_date'] : "" : "",
            employeeUuid:params['employee_uuid'] !== "" ? params['employee_uuid'] !== undefined ? params['employee_uuid'] : null : null,
            productUuid:params['product_uuid'] !== "" ? params['product_uuid'] !== undefined ? params['product_uuid'] : null : null,
            vendingMachineUuid:params['vending_machine_uuid'] !== "" ? params['vending_machine_uuid'] !== undefined ? params['vending_machine_uuid'] : "" : "",
            aggregation:params['aggregation'] !== "" ? params['aggregation'] !== undefined ? params['aggregation'] : Aggregation.NONE : Aggregation.NONE,
            numberOfTransactionsPerPage:params['trans_per_page'] !== "" ? params['trans_per_page'] !== undefined ? parseInt(params['trans_per_page']) : 25 : 25,
            currentPage:params['page'] !== "" ? params['page'] !== undefined ? parseInt(params['page'])-1 : 0 : 0,
            jobCode:params['job_code'] !== "" ? params['job_code'] !== undefined ? params['job_code'] : "" : "",
        }, ()=>{
            this.getEmployeeByUuid()
            this.reloadTransaction()
        })
    }

    reloadTransaction(isPageChange = false){
        let startEpoch = getStartOfDay(this.state.startDate);
        let endEpoch = getEndOfDay(this.state.endDate);

        if (this.state.aggregation === Aggregation.NONE) {
            getAdminTransactionServiceApi().adminTransactionServiceGetTransactions({
                page: this.state.currentPage+1,
                employee_uuid: this.state.employeeUuid ?? "",
                product_uuid: this.state.productUuid ?? "",
                vending_machine_uuid: this.state.vendingMachineUuid,
                locker_uuid: this.state.lockerUuid,
                per: this.state.numberOfTransactionsPerPage,
                start_epoch: startEpoch,
                end_epoch: endEpoch,
                job_code: this.state.jobCode
            }).then((response)=>{
                if (response.data.success){
                    this.setState({
                        transactions: response.data.transactions ?? [],
                        pageCount:response.data.number_of_page ?? 1,
                    });
                } else {
                    window.alert(response.data.error_message ?? "");
                }
            }).catch(()=>{
                window.alert("Server error");
            }).finally(()=>{

            });

            if (!isPageChange){
                const timestamp = moment().unix();
                this.setState({isChartLoading:true, chartLoadingTimeStamp:timestamp})
                getAdminTransactionServiceApi().adminTransactionServiceGetTransactionsCount({
                    employee_uuid: this.state.employeeUuid ?? "",
                    product_uuid: this.state.productUuid ?? "",
                    vending_machine_uuid: this.state.vendingMachineUuid,
                    locker_uuid: this.state.lockerUuid,
                    start_epoch: startEpoch,
                    end_epoch: endEpoch,
                    aggregation: this.state.transactionChartAggregation,
                    job_code: this.state.jobCode
                }).then((response)=>{
                    if (this.state.chartLoadingTimeStamp === timestamp){
                        this.setState({transactionsCount:response.data.transactions_count ?? [], isChartLoading:false})
                    }
                }).catch(()=>{

                }).finally(()=>{

                });

            }

        } else {
            getAdminTransactionServiceApi().adminTransactionServiceGetAggregatedTransaction({
                start_epoch: startEpoch,
                end_epoch: endEpoch,
                employee_uuid: this.state.employeeUuid ?? "",
                product_uuid:this.state.productUuid ?? "",
                job_code: this.state.jobCode,
                vending_machine_uuid:this.state.vendingMachineUuid,
                locker_uuid: this.state.lockerUuid,
                aggregate_by: this.getAggregateBy(),
                order_by: this.getOrderBy(),
                per_page: this.state.numberOfTransactionsPerPage,
                page: this.state.currentPage+1
            }).then((response)=>{
                console.log("all agg transactions", response)
                if (response.data.success){
                    this.setState({
                        allEmployeeAggregatedTransactions: response.data.aggregated_by_employee_transaction ?? [],
                        allProductAggregatedTransactions: response.data.aggregated_by_product_transaction ?? [],
                        allMachineAggregatedTransactions: response.data.aggregated_by_machine_transaction ?? [],
                        pageCount: response.data.total_page ?? 1
                    })
                } else {
                    window.alert(response.data.error_message)
                }
            })
        }
    }

    reloadAllLocker(){
        getAdminLockerServiceApi().adminLockerServiceListLockers().then((response)=>{
            if (response.data.success ?? false){
                this.setState({
                    allLockers: response.data.lockers ?? []
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{

        })
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({currentPage: selectedItem.selected}, () => {
            this.reloadTransaction(true);
            this.handleParamsChange("page", (selectedItem.selected + 1).toString())
        });
    }

    handleParamsChange(currentParam: string, newValue:string){
        const qs = require('qs');
        let params = qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    handleAllParamsChange(allParams:any){
        const qs = require('qs');
        let urlParams = qs.parse(this.props.location.search.slice(1));
        const localKeys = Object.keys(allParams);
        localKeys.forEach((localKey)=>{
            // @ts-ignore
            urlParams[localKey] = allParams[localKey]
        })
        const urlKeys = Object.keys(urlParams);
        let urlParamsStr = "?";
        urlKeys.forEach((urlKey)=>{
            urlParamsStr = urlParamsStr + urlKey + "=" + urlParams[urlKey] + "&"
        })
        this.props.history.push(urlParamsStr);
    }

    handleStartDateChange(date:Date | null){
        this.setState({startDate:date !== null ? moment(date).format('YYYY-MM-DD') : "", currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("start_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
    }

    handleEndDateChange(date:Date | null){
        this.setState({endDate:date !== null ? moment(date).format('YYYY-MM-DD') : "", currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("end_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
    }

    onEmployeeChange(employees: Array<PpeEmployee>) {
        if (employees.length > 0) {
            this.setState({selectedEmployee: employees[0], employeeUuid: employees[0].uuid ?? null, currentPage:0}, ()=>{this.reloadTransaction()});
            this.handleParamsChange("employee_uuid", employees[0]?.uuid ?? "")
        } else {
            this.setState({selectedEmployee:null, employeeUuid:null, currentPage:0}, ()=>{this.reloadTransaction()})
            this.handleParamsChange("employee_uuid", "")
        }
    }

    onProductChange(products: Array<PpeProductInfo>) {
        if (products.length > 0) {
            this.setState({selectedProduct: products[0], productUuid: products[0].uuid ?? null, currentPage:0}, ()=>{this.reloadTransaction()});
            this.handleParamsChange("product_uuid", products[0]?.uuid ?? "")
        } else {
            this.setState({selectedProduct: null, productUuid:null, currentPage:0}, ()=>{this.reloadTransaction()})
            this.handleParamsChange("product_uuid", "")
        }
    }

    onEmployeeClick(employeeUuid:string){
        this.setState({employeeUuid:employeeUuid, currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("employee_uuid", employeeUuid)
    }

    onVendingMachineChange(e:any){
        this.setState({vendingMachineUuid:e.target.value, currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("vending_machine_uuid", e.target.value)
    }

    onLockerChange(e:any){
        this.setState({lockerUuid:e.target.value, currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("locker_uuid", e.target.value)
    }

    onAggregateByChange(e:any){
        this.setState({aggregation:e.target.value, currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("aggregation", e.target.value)
    }

    onTransPerPageChange(e:any){
        this.setState({numberOfTransactionsPerPage: parseInt(e.target.value), currentPage:0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("trans_per_page", e.target.value)
    }

    onJobCodeChange(e:any){
        this.setState({jobCode: e.target.value, currentPage: 0}, ()=>{this.reloadTransaction()})
        this.handleParamsChange("job_code", e.target.value);
    }

    onOrderByName(e:any){
        this.setState({
            aggOrderBy:OrderBy.NAME,
            aggOrderSequence:this.state.aggOrderSequence === OrderSequence.DESC ? OrderSequence.ASC : OrderSequence.DESC
        }, ()=>{this.reloadTransaction()})
    }

    onOrderByCount(e:any){
        this.setState({
            aggOrderBy:OrderBy.COUNT,
            aggOrderSequence:this.state.aggOrderSequence === OrderSequence.DESC ? OrderSequence.ASC : OrderSequence.DESC
        }, ()=>{this.reloadTransaction()})
    }

    handleDateClick(date:any){
        this.setState({startDate:date !== null ? moment(date).format('YYYY-MM-DD') : "", endDate:date !== null ? moment(date).format('YYYY-MM-DD') : "",  currentPage:0}, ()=>{this.reloadTransaction()})
        // this.handleParamsChange("start_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
        // this.handleParamsChange("end_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
        this.handleAllParamsChange({
            "start_date":date !== null ? moment(date).format('YYYY-MM-DD') : "",
            "end_date":date !== null ? moment(date).format('YYYY-MM-DD') : "",
        })
    }

    handleTransactionChartAggregationChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({transactionChartAggregation: e.target.value}, ()=>{this.reloadTransaction()})
    }

    onSearchProduct(query:string){
        this.setState({isproductOptionsLoading: true});
        getAdminProductServiceAPI().adminProductServiceSearchProducts({
            only_current:false,
            term: query,
            per_page:10000,
            page: 1
        }).then((response)=>{
            if (response.data.success){
                this.setState({
                    productOptions:response.data.products ?? []
                })
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(reason => {
            window.alert("Server error")
        }).finally(()=>{
            this.setState({
                isproductOptionsLoading:false,
            })
        })
    }


    render() {
        const rows = this.state.transactions.map((transaction)=>{
            return <TransactionRow transaction={transaction} timezone={this.props.timezone} reload={this.reloadTransaction.bind(this)}/>
        })

        let aggRows: {} | null | undefined = []

        if (this.state.aggregation === Aggregation.VENDINGMACHINE){
            aggRows = this.state.allMachineAggregatedTransactions.map((agg)=>{
                return <tr key={agg.machine_uuid}>
                    <td>{agg.machine_name}</td>
                    <td>{agg.count}</td>
                </tr>
            })
        }
        if (this.state.aggregation === Aggregation.ITEM){
            aggRows = this.state.allProductAggregatedTransactions.map((agg)=>{
                return <tr key={agg.product_uuid}>
                    <td>{agg.product_name}</td>
                    <td>{agg.count}</td>
                </tr>
            })
        }
        if (this.state.aggregation === Aggregation.EMPLOYEE){
            aggRows = this.state.allEmployeeAggregatedTransactions.map((agg)=>{
                return <tr key={agg.employee_uuid}>
                    <td>{agg.employee_name}</td>
                    <td>{agg.count}</td>
                </tr>
            })
        }

        const renderEmployeeItemChildren = (option: PpeEmployee, props:any) => {
            return [
                <Highlighter key={"full_name"} search={props.text}>{option.full_name}</Highlighter>,
            ];
        };

        const selectedEmployee = this.state.selectedEmployee ? [this.state.selectedEmployee] : [];

        const renderProductItemChildren = (option: PpeProductInfo, props:any) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
            ];
        };

        const selectedProduct = this.state.selectedProduct ? [this.state.selectedProduct] : [];

        const vendingMachineOption = this.props.allVendingMachines.map((vendingMachine)=>{
            return <option key={vendingMachine.uuid} value={vendingMachine.uuid}>{vendingMachine.name}</option>
        })
        const lockerOptions = this.state.allLockers.map((locker)=>{
            return <option key={locker.uuid} value={locker.uuid}>{locker.name}</option>
        })

        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Transactions - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Transaction Records</h4>
                                </div>
                                <div>
                                    {
                                        this.state.aggregation === Aggregation.NONE && <DownloadTransactionsCsv postAttributes={{
                                            start_epoch: getStartOfDay(this.state.startDate),
                                            end_epoch: getEndOfDay(this.state.endDate),
                                            employee_uuid:this.state.employeeUuid ?? "",
                                            product_uuid:this.state.productUuid ?? "",
                                            vending_machine_uuid:this.state.vendingMachineUuid,
                                            locker_uuid: this.state.lockerUuid,
                                            per:10000,
                                            page:1}}/>
                                    }
                                    {
                                        this.state.aggregation !== Aggregation.NONE && <DownloadAggregationCsv
                                            postAttributes={{
                                                start_epoch: getStartOfDay(this.state.startDate),
                                                end_epoch: getEndOfDay(this.state.endDate),
                                                employee_uuid: this.state.employeeUuid ?? "",
                                                product_uuid:this.state.productUuid ?? "",
                                                job_code: this.state.jobCode,
                                                vending_machine_uuid:this.state.vendingMachineUuid,
                                                locker_uuid: this.state.lockerUuid,
                                                aggregate_by: this.getAggregateBy(),
                                                order_by: this.getOrderBy(),
                                                per_page: 9999999,
                                                page: 1
                                            }} aggBy={this.state.aggregation}/>
                                    }
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <form>
                                            <div className="form-row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="startDate">Start Date: </label>
                                                    <DatePicker className={"form-control"}
                                                                selected={this.state.startDate !== "" ? new Date(this.state.startDate) : null}
                                                                onChange={this.handleStartDateChange.bind(this)}
                                                                dateFormat="dd/MM/yyyy"
                                                                wrapperClassName={"datePicker"}
                                                                maxDate={new Date()}
                                                                isClearable
                                                    />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="endDate">End Date: </label>
                                                    <DatePicker className={"form-control"}
                                                                selected={this.state.endDate !== "" ? new Date(this.state.endDate) : null}
                                                                onChange={this.handleEndDateChange.bind(this)}
                                                                dateFormat="dd/MM/yyyy"
                                                                wrapperClassName={"datePicker"}
                                                                maxDate={new Date()}
                                                                isClearable
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-2">
                                                    <label htmlFor="employee">Employee:</label>
                                                    <AsyncTypeahead
                                                        id={"typeahead"}
                                                        isLoading={this.state.isEmployeeOptionsLoading}
                                                        labelKey={(option)=>{
                                                            return option.full_name ?? ""
                                                        }}
                                                        onSearch={(query) => {
                                                            this.setState({isEmployeeOptionsLoading: true});
                                                            getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
                                                                term: query,
                                                                per_page:10000,
                                                                page: 1
                                                            }).then((response)=>{
                                                                if (response.data.success){
                                                                    this.setState({
                                                                        employeeOptions:response.data.data?.employees ?? []
                                                                    })
                                                                } else {
                                                                    window.alert(response.data.error_message)
                                                                }
                                                            }).catch(reason => {
                                                                window.alert("Server error")
                                                            }).finally(()=>{
                                                                this.setState({
                                                                    isEmployeeOptionsLoading:false,
                                                                })
                                                            })
                                                        }}
                                                        selected={selectedEmployee}
                                                        onChange={this.onEmployeeChange.bind(this)}
                                                        options={this.state.employeeOptions}
                                                        renderMenuItemChildren={renderEmployeeItemChildren}
                                                        clearButton
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-2 form-group">
                                                    <label htmlFor="items">Product:</label>
                                                    <AsyncTypeahead
                                                        id={"typeahead"}
                                                        isLoading={this.state.isproductOptionsLoading}
                                                        labelKey={(option)=>{
                                                            return option.name ?? ""
                                                        }}
                                                        onSearch={this.onSearchProduct.bind(this)}
                                                        selected={selectedProduct}
                                                        onChange={this.onProductChange.bind(this)}
                                                        options={this.state.productOptions}
                                                        renderMenuItemChildren={renderProductItemChildren}
                                                        clearButton
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-2 form-group">
                                                    <label htmlFor="items">Job Code:</label>
                                                    <input className="form-control" type="text" onChange={this.onJobCodeChange.bind(this)} value={this.state.jobCode}/>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-3 mb-3 form-group">
                                                    <label htmlFor="vendingMachines">Vending Machine:</label>
                                                    <select disabled={this.state.lockerUuid !== ""} name="vendingMachine" id="vendingMachines"
                                                            className="form-control" value={this.state.vendingMachineUuid} onChange={this.onVendingMachineChange.bind(this)}>
                                                        <option value="">All</option>
                                                        {vendingMachineOption}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 mb-3 form-group">
                                                    <label htmlFor="vendingMachines">Locker:</label>
                                                    <select disabled={this.state.vendingMachineUuid !== ""} name="vendingMachine" id="vendingMachines"
                                                            className="form-control" value={this.state.lockerUuid} onChange={this.onLockerChange.bind(this)}>
                                                        <option value="">All</option>
                                                        {lockerOptions}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 mb-3 form-group">
                                                    <label htmlFor="items">Aggregate By:</label>
                                                    <select name="item" id="items" className="form-control" value={this.state.aggregation} onChange={this.onAggregateByChange.bind(this)}>
                                                        <option value={Aggregation.NONE}>None</option>
                                                        <option value={Aggregation.EMPLOYEE}>Employee</option>
                                                        <option value={Aggregation.VENDINGMACHINE}>Machine</option>
                                                        <option value={Aggregation.ITEM}>Product</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3 mb-3 form-group">
                                                    <label htmlFor="items">Records Per Page:</label>
                                                    <select className="form-control" onChange={this.onTransPerPageChange.bind(this)} value={this.state.numberOfTransactionsPerPage}>
                                                        <option value={10}>10</option>
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {this.state.aggregation === Aggregation.NONE && <Fragment>
                                    <hr/>
                                    <TransactionChart aggregation={this.state.transactionChartAggregation} handleDateAggChange={this.handleTransactionChartAggregationChange.bind(this)} chartData={this.state.transactionsCount} isLoading={this.state.isChartLoading} handleDateClick={this.handleDateClick.bind(this)}/>
                                </Fragment>}
                                <hr/>
                                {
                                    this.state.aggregation === Aggregation.NONE &&
                                    <div>
                                        <table id="datatable" className="table table-striped table-bordered">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">UUID</th>
                                                <th scope="col">Employee</th>
                                                <th scope="col">Key Card Number</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">Vending Machine/Locker</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Job ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    this.state.aggregation !== Aggregation.NONE &&
                                    <div>
                                        <table id="datatable" className="table table-striped table-bordered">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className={this.state.aggOrderBy === OrderBy.NAME ? "selected" : ""}>
                                                    <div style={{cursor:"pointer"}}  onClick={this.onOrderByName.bind(this)}>
                                                        <span style={{marginRight:"1em"}}>
                                                            {this.state.aggregation === Aggregation.EMPLOYEE && "Employee"}
                                                            {this.state.aggregation === Aggregation.ITEM && "Product"}
                                                            {this.state.aggregation === Aggregation.VENDINGMACHINE && "Vending Machine/Locker"}
                                                        </span>
                                                        {
                                                            this.state.aggOrderSequence === OrderSequence.ASC && this.state.aggOrderBy === OrderBy.NAME &&
                                                            <span className="table-up">
                                                            <a className="indigo-text">
                                                                <i className="fa fa-long-arrow-up" aria-hidden="true">
                                                                </i>
                                                            </a>
                                                        </span>
                                                        }
                                                        {
                                                            this.state.aggOrderSequence === OrderSequence.DESC && this.state.aggOrderBy === OrderBy.NAME &&
                                                            <span className="table-down">
                                                            <a className="indigo-text">
                                                                <i className="fa fa-long-arrow-down" aria-hidden="true">
                                                                </i>
                                                            </a>
                                                        </span>
                                                        }
                                                    </div>
                                                </th>
                                                <th scope="col" className={this.state.aggOrderBy === OrderBy.COUNT ? "selected" : ""}>
                                                    <div style={{cursor:"pointer"}} onClick={this.onOrderByCount.bind(this)}>
                                                        <span style={{marginRight:"1em"}}>
                                                        Count
                                                        </span>
                                                        {
                                                            this.state.aggOrderSequence === OrderSequence.ASC && this.state.aggOrderBy === OrderBy.COUNT &&
                                                            <span className="table-up">
                                                            <a className="indigo-text">
                                                                <i className="fa fa-long-arrow-up" aria-hidden="true">
                                                                </i>
                                                            </a>
                                                        </span>
                                                        }
                                                        {
                                                            this.state.aggOrderSequence === OrderSequence.DESC && this.state.aggOrderBy === OrderBy.COUNT &&
                                                            <span className="table-down">
                                                            <a className="indigo-text">
                                                                <i className="fa fa-long-arrow-down" aria-hidden="true">
                                                                </i>
                                                            </a>
                                                        </span>
                                                        }
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {aggRows}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state.pageCount}
                                    initialPage={this.state.currentPage}
                                    onPageChange={this.handlePageChange.bind(this)}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"} forcePage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export const TransactionPage = withGlobalData(_TransactionPage)
