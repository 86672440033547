import React, { Component} from 'react';
import {getAdminTransactionServiceApi} from "../global/api";
import {CSVLink} from "react-csv";
import moment from "moment";
import {PpeGetTransactionsRequest} from "../gen";
import {Ppeemployerv1Transaction} from "../gen/typescript-axios";


interface Props {
    postAttributes : PpeGetTransactionsRequest
}

interface State {
    csvData: Ppeemployerv1Transaction[]
    ready:boolean
}

class DownloadTransactionsCsv extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            csvData: [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    sleep(milliseconds:number) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    getData(){
        getAdminTransactionServiceApi().adminTransactionServiceGetTransactions(this.props.postAttributes).then((response)=>{
            if (response.data.success ?? false){
                this.setState({
                    csvData: response.data.transactions ?? []
                }, ()=>{
                    this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
                })
            } else {
                window.alert(response.data.error_message ?? "");
            }
        }).catch(()=>{
            window.alert("Server error");
        }).finally(()=>{

        });
    }

    prepareCsvHeader(){
        return [
            {label: 'UUID', key: 'uuid'},
            {label: 'Type', key: 'type'},
            {label: 'Employee', key: 'employee_name'},
            {label: 'Department', key: 'employee_department'},
            {label: 'Key Card', key: 'key_card'},
            {label: 'Date', key: 'date'},
            {label: 'Item Name', key: 'product_name'},
            {label: 'Item Number', key: 'selection'},
            {label: 'Vending Machine/Locker', key: 'machine_name'},
            {label: 'Site', key: 'machine_site'},
            {label: 'Job ID', key: 'job_id'},
            {label: 'Manufacturer', key: 'product.manufacturer'},
            {label: 'Manufacturer Code', key: 'product.manufacturer_code'},
            {label: 'Supplier', key: 'product.supplier'},
            {label: 'Supplier Code', key: 'product.supplier_code'},
        ]
    }

    prepareCsvData(){
        return this.state.csvData.map((transaction)=>{
            let transactionExport:any = transaction
            transactionExport['date'] = moment.unix(transaction.event_epoch ?? 0).toString()
            return transactionExport;
        });
    }

    render() {
        return <div>
            <button className="btn btn-primary btn-sm" onClick={this.getData.bind(this)}>Download as CSV</button>

            <CSVLink
                data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                filename={'transactions_'+moment().unix()+'_.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadTransactionsCsv}
