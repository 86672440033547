import axios, {AxiosInstance, AxiosPromise} from 'axios';
import {
    // AdminCompanyInformationServiceApi,
    AdminLockerServiceApi,
    AdminKeyCardServiceApi,
    // AdminProductServiceApi,
    AdminAuditLogServiceApi,
    AdminSelfServiceServiceApi,
    AdminServiceNowServiceApi,
    AdminTrackableItemServiceApi,
    // AdminTransactionServiceApi,
    // AdminUserServiceApi,
    Configuration,
    // AdminVendingMachineServiceApi,
    AdminTemporaryAccessServiceApi,
    AdminOrderServiceApi,
    AdminAuthenticationServiceApi,
    // AdminJobServiceApi,
    AdminSsoServiceApi,
    PartnerUserAuthenticationServiceApi,
    PartnerUserPartnerUserServiceApi, PartnerUserPartnerServiceApi, AdvancedReportingServiceApi
} from "../gen";
import {OrganizationServiceApi, SsoServiceApi, TransactionServiceApi, UserServiceApi} from "../gen-employee";
import {AdminDedicatedLockerServiceApi,AdminVendingMachineServiceApi,AdminOrganizationServiceApi,AdminFeedbackServiceApi,AdminProductServiceApi,AdminTransactionServiceApi,AdminJobServiceApi,AdminUserServiceApi,AdminTagServiceApi,AdminCompanyInformationServiceApi} from "../gen/typescript-axios";

interface AdminServicesRegistry {
    self_servicing: AdminSelfServiceServiceApi | null
    auth: AdminAuthenticationServiceApi | null
    service_now: AdminServiceNowServiceApi | null
    trackable_items: AdminTrackableItemServiceApi | null
    lockers: AdminLockerServiceApi | null
    products: AdminProductServiceApi | null
    company_information: AdminCompanyInformationServiceApi | null
    user: AdminUserServiceApi | null,
    organization: AdminOrganizationServiceApi | null
    audit_log: AdminAuditLogServiceApi | null
    transaction: AdminTransactionServiceApi | null,
    key_card: AdminKeyCardServiceApi | null,
    vending_machine: AdminVendingMachineServiceApi | null
    temporary_access: AdminTemporaryAccessServiceApi | null,
    order: AdminOrderServiceApi | null,
    job: AdminJobServiceApi | null,
    tag: AdminTagServiceApi | null,
    sso: AdminSsoServiceApi | null,
    dedicated_lockers: AdminDedicatedLockerServiceApi | null,
    feedback: AdminFeedbackServiceApi | null,
    advancedReporting: AdvancedReportingServiceApi | null,
}

interface EmployeeServicesRegistry {
    user_service: UserServiceApi | null
    transaction_service: TransactionServiceApi | null
    sso_service: SsoServiceApi | null
    organization_service: OrganizationServiceApi | null
}

interface PartnerServiceRegistry {
    partner_user_authentication_service: PartnerUserAuthenticationServiceApi | null
    partner_user_partner_user_service: PartnerUserPartnerUserServiceApi | null
    partner_user_partner_service: PartnerUserPartnerServiceApi | null
}

let adminServices: AdminServicesRegistry = {
    auth: null,
    self_servicing: null,
    service_now: null,
    trackable_items: null,
    lockers: null,
    products: null,
    company_information: null,
    transaction: null,
    user: null,
    organization: null,
    audit_log: null,
    key_card: null,
    vending_machine: null,
    temporary_access: null,
    order: null,
    job: null,
    tag: null,
    sso: null,
    dedicated_lockers: null,
    feedback: null,
    advancedReporting: null,
}

let employeeService: EmployeeServicesRegistry = {
    user_service: null,
    transaction_service: null,
    sso_service: null,
    organization_service: null
}

let partnerService: PartnerServiceRegistry = {
    partner_user_authentication_service: null,
    partner_user_partner_user_service: null,
    partner_user_partner_service: null
}

function _getAxiosInstance(): AxiosInstance {
    const authentication_token = localStorage.getItem("authentication_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authentication_token === null ? "" : `Bearer ${authentication_token}`)
        }
    });

    return instance;
}

function getAxiosInstance(): AxiosInstance {
    // note: we can't cache axios instance here.

    return _getAxiosInstance();
}

export function getPartnerUserAuthenticationServiceApi():  PartnerUserAuthenticationServiceApi {
    if (!partnerService.partner_user_authentication_service) {
        partnerService.partner_user_authentication_service = new PartnerUserAuthenticationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return partnerService.partner_user_authentication_service;
}

export function getPartnerUserPartnerUserServiceApi():  PartnerUserPartnerUserServiceApi {
    if (!partnerService.partner_user_partner_user_service) {
        partnerService.partner_user_partner_user_service = new PartnerUserPartnerUserServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return partnerService.partner_user_partner_user_service;
}

export function getPartnerUserPartnerServiceApi(): PartnerUserPartnerServiceApi {
    if (!partnerService.partner_user_partner_service) {
        partnerService.partner_user_partner_service = new PartnerUserPartnerServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return partnerService.partner_user_partner_service;
}


export function getAdminSelfServiceApi(): AdminSelfServiceServiceApi {
    if (!adminServices.self_servicing) {
        adminServices.self_servicing = new AdminSelfServiceServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.self_servicing;
}

export function getAdminLockerServiceApi(): AdminLockerServiceApi {
    if (!adminServices.lockers) {
        adminServices.lockers = new AdminLockerServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.lockers;
}

export function getAdminServiceNowServiceApi(): AdminServiceNowServiceApi {
    if (!adminServices.service_now) {
        adminServices.service_now = new AdminServiceNowServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.service_now;
}

export function getAdminTransactionServiceApi(): AdminTransactionServiceApi {
    if (!adminServices.transaction) {
        adminServices.transaction = new AdminTransactionServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.transaction;
}


export function getTrackableItemsServiceAPI(): AdminTrackableItemServiceApi {
    if (!adminServices.trackable_items) {
        adminServices.trackable_items = new AdminTrackableItemServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.trackable_items;
}

export function getAdminAuthServiceAPI(): AdminAuthenticationServiceApi {
    if (!adminServices.auth) {
        adminServices.auth = new AdminAuthenticationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return adminServices.auth;
}

export function getAdminProductServiceAPI(): AdminProductServiceApi {
    if (!adminServices.products) {
        adminServices.products = new AdminProductServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.products;
}

export function getAdminCompanyInformationServiceAPI(): AdminCompanyInformationServiceApi {
    if (!adminServices.company_information) {
        adminServices.company_information = new AdminCompanyInformationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.company_information;
}

export function getAdminUserServiceAPI(): AdminUserServiceApi {
    if (!adminServices.user) {
        adminServices.user = new AdminUserServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.user;
}

export function getAdminOrganizationServiceAPI(): AdminOrganizationServiceApi {
    if (!adminServices.organization) {
        adminServices.organization = new AdminOrganizationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.organization;
}

export function getAdminFeedbackServiceAPI(): AdminFeedbackServiceApi {
    if (!adminServices.feedback) {
        adminServices.feedback = new AdminFeedbackServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.feedback;
}

export function getAdvancedReporting(): AdvancedReportingServiceApi {
    if (!adminServices.advancedReporting) {
        adminServices.advancedReporting = new AdvancedReportingServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.advancedReporting;
}

export function getAdminAuditLogServiceAPI(): AdminAuditLogServiceApi {
    if (!adminServices.audit_log) {
        adminServices.audit_log = new AdminAuditLogServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.audit_log;
}

export function getAdminKeyCardServiceAPI(): AdminKeyCardServiceApi {
    if (!adminServices.key_card) {
        adminServices.key_card = new AdminKeyCardServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.key_card;
}

export function getAdminVendingMachineServiceAPI(): AdminVendingMachineServiceApi {
    if (!adminServices.vending_machine) {
        adminServices.vending_machine = new AdminVendingMachineServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.vending_machine;
}

export function getAdminTemporaryAccessServiceAPI(): AdminTemporaryAccessServiceApi {
    if (!adminServices.temporary_access) {
        adminServices.temporary_access = new AdminTemporaryAccessServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.temporary_access;
}

export function getAdminOrderServiceAPI(): AdminOrderServiceApi {
    if (!adminServices.order) {
        adminServices.order = new AdminOrderServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.order;
}

export function getAdminJobServiceAPI(): AdminJobServiceApi {
    if (!adminServices.job) {
        adminServices.job = new AdminJobServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.job;
}

export function getAdminTagServiceAPI(): AdminTagServiceApi {
    if (!adminServices.tag) {
        adminServices.tag = new AdminTagServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.tag;
}

export function getAdminSsoServiceAPI(): AdminSsoServiceApi {
    if (!adminServices.sso) {
        adminServices.sso = new AdminSsoServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.sso;
}

export function getAdminDedicatedLockerServiceAPI(): AdminDedicatedLockerServiceApi {
    if (!adminServices.dedicated_lockers) {
        adminServices.dedicated_lockers = new AdminDedicatedLockerServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return adminServices.dedicated_lockers;
}

export function getEmployeeUserServiceApi(): UserServiceApi {
    if (!employeeService.user_service) {
        employeeService.user_service = new UserServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return employeeService.user_service;
}

export function getEmployeeTransactionServiceApi(): TransactionServiceApi {
    if (!employeeService.transaction_service) {
        employeeService.transaction_service = new TransactionServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return employeeService.transaction_service;
}

export function getEmployeeSsoServiceApi(): SsoServiceApi {
    if (!employeeService.sso_service) {
        employeeService.sso_service = new SsoServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return employeeService.sso_service;
}

export function getEmployeeOrganizationServiceApi(): OrganizationServiceApi {
    if (!employeeService.organization_service) {
        employeeService.organization_service = new OrganizationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }
    return employeeService.organization_service;
}

export function removeAllApiServices(){
    adminServices = {
        auth: null,
        self_servicing: null,
        service_now: null,
        trackable_items: null,
        lockers: null,
        products: null,
        company_information: null,
        transaction: null,
        user: null,
        organization: null,
        audit_log: null,
        key_card: null,
        vending_machine: null,
        temporary_access: null,
        order: null,
        job: null,
        tag: null,
        sso: null,
        dedicated_lockers: null,
        feedback: null,
        advancedReporting: null,
    }
    employeeService = {
        user_service: null,
        transaction_service: null,
        sso_service: null,
        organization_service: null
    }
    partnerService = {
        partner_user_authentication_service: null,
        partner_user_partner_user_service: null,
        partner_user_partner_service: null
    }
}

function postAPI(path: string, data: any, operation?: string, contentType?: string): AxiosPromise<any> {
    const authentication_token = localStorage.getItem("authentication_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authentication_token === null ? "" : `Bearer ${authentication_token}`)
        }
    });

    let op = instance.post;
    if (operation === "DELETE") {
        op = instance.delete;
        return op(path)
    }
    return op(path, JSON.stringify(data));
}

function getAPI(path: string): AxiosPromise<any> {

    const authentication_token = localStorage.getItem("authentication_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authentication_token === null ? "" : `Bearer ${authentication_token}`)
        }
    });

    return instance.get(path);
}



export {postAPI, getAPI};
