import React, { Component } from 'react';
import { getAdminOrganizationServiceAPI, getAdminUserServiceAPI } from "../global/api";
import { Message, UserType } from "../global/models";
import { AdminRow } from "./admin_row";
import { AddAdminModal } from "./add_admin_modal";
import { Helmet } from "react-helmet";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import 'react-phone-input-2/lib/style.css'
import { ServiceNowCard } from "./service_now_card";
import codes, { ICountryCodeItem } from 'country-calling-code';
import {
    Ppeemployerv1Admin,
    Ppeemployerv1JobIDStatus,
    Ppeemployerv1Organization,
    Ppeemployerv1AccessCodeMode,
    Ppeemployerv1Role
} from "../gen/typescript-axios";
import BusyIndicator, { LoadingSpanner } from "../busy_indicator";
import { EditAdminRolesModal } from "./edit_admin_roles_modal";
import MachineCompanyModal from './origanzation_clone_modal';
import OriganzationCloneModal from './origanzation_clone_modal';


interface Props extends IGlobalDataContext {
}

interface State {
    admins: Ppeemployerv1Admin[]
    isAddAdminModalOpen: boolean
    isOriganzationCloneModalOpen: boolean
    selectedAdmin: Ppeemployerv1Admin | null,
    isEditAdminRolesModalOpen: boolean
    newLogo: any
    phoneNumber: string
    countryCode: string
    isMobileAppAllowed: boolean
    isMifareCardAllowed: boolean
    organization: Ppeemployerv1Organization | null
    jobIdStatus: Ppeemployerv1JobIDStatus,
    isUpdateJobIdStatus: boolean
    isShowDisabledAdmin: boolean
    timezone: string,
    isEnableLowStockNotification: boolean,
    accessCodeLoginEnabled: boolean,
    accessCodeMode: Ppeemployerv1AccessCodeMode,
}

class _CompanyProfilePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            admins: [],
            isAddAdminModalOpen: false,
            isOriganzationCloneModalOpen: false,
            selectedAdmin: null,
            isEditAdminRolesModalOpen: false,
            newLogo: null,
            phoneNumber: "",
            countryCode: "",
            isMobileAppAllowed: false,
            isMifareCardAllowed: false,
            organization: null,
            jobIdStatus: Ppeemployerv1JobIDStatus.Disabled,
            isUpdateJobIdStatus: false,
            isShowDisabledAdmin: false,
            timezone: "",
            isEnableLowStockNotification: false,
            accessCodeLoginEnabled: false,
            accessCodeMode: Ppeemployerv1AccessCodeMode.Numeric,
        };
    }

    componentDidMount() {
        this.reloadListOfAdmins();
        this.reloadOrganization();
    }

    reloadOrganization() {
        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response) => {
            if (response.data.success ?? false) {
                this.setState({
                    organization: response.data.organization ?? null,
                    phoneNumber: response.data.organization?.phone_number?.contact_number ?? "",
                    countryCode: response.data.organization?.phone_number?.country_code ?? "",
                    isMobileAppAllowed: response.data.organization?.pickup_methods?.mobile_app ?? false,
                    isMifareCardAllowed: response.data.organization?.pickup_methods?.mifare_card ?? false,
                    jobIdStatus: response.data.organization?.job_id_status ?? Ppeemployerv1JobIDStatus.Disabled,
                    timezone: response.data.organization?.timezone ?? "Australia/Sydney",
                    isEnableLowStockNotification: response.data.organization?.notification_setting?.is_enable_low_stock_notification ?? false,
                    accessCodeLoginEnabled: response.data.organization?.access_code_login_enabled ?? false,
                    accessCodeMode: response.data.organization?.access_code_mode ?? Ppeemployerv1AccessCodeMode.Numeric,
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(() => {
            window.alert("Server error")
        })
    }

    reloadListOfAdmins() {
        getAdminUserServiceAPI().adminUserServiceListAdmins().then((response) => {
            if (response.data.success ?? false) {
                this.setState({ admins: response.data.admins ?? [] })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(() => {
            window.alert("Server error")
        })
    }

    handleLogoUpload(e: any) {
        const file = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({
                newLogo: reader.result
            })
        };
    }

    closeAddAdminModal() {
        this.setState({ isAddAdminModalOpen: false })
    }

    openAddAdminModal() {
        this.setState({ isAddAdminModalOpen: true })
    }

    openOriganzationModal() {
        this.setState({ isOriganzationCloneModalOpen: true })
    }

    closeEditAdminRolesModal() {
        this.setState({ isEditAdminRolesModalOpen: false })
    }

    openEditAdminRolesModal(selectedAdmin: Ppeemployerv1Admin) {
        this.setState({
            isEditAdminRolesModalOpen: true,
            selectedAdmin: selectedAdmin
        })
    }

    setPhoneNumber(e: any) {
        this.setState({ phoneNumber: e.target.value })
    }

    toggleShowDisabledAdmin(event: React.ChangeEvent<HTMLInputElement>) {
        console.log("Checked: " + event.target.checked);
        this.setState({ isShowDisabledAdmin: event.target.checked })
    }

    savePhoneNumber(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdatePhoneNumber({
            country_code: this.state.countryCode,
            contact_number: this.state.phoneNumber,
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Phone Number Updated", "success"))
                this.reloadOrganization()
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }

    saveLogo(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdateOrganizationLogo({
            logo: this.state.newLogo
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Logo Uploaded", "success"))
                this.props.reloadCompanyLogo();
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }

    handleIsMobileAppAllowedChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isMobileAppAllowed: e.target.value === "true" })
    }

    handleIsEnableLowStockNotificationChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isEnableLowStockNotification: e.target.value === "true" })
    }

    handleJobIdStatusChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ jobIdStatus: e.target.value })
    }

    handleAccessCodeLoginEnabledChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ accessCodeLoginEnabled: e.target.value === "true" })
    }

    handleAccessCodeModeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ accessCodeMode: e.target.value })
    }

    handleIsMifareCardAllowedChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isMifareCardAllowed: e.target.value === "true" })
    }

    updatePickUpMethods(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdatePickupMethods({
            is_mobile_app_allowed: this.state.isMobileAppAllowed,
            is_mifare_card_allowed: this.state.isMifareCardAllowed
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Pick up methods updated", "success"))
                this.reloadOrganization();
                window.location.reload(); // force reload when update pickup method
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }


    updateNotificationSetting(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdateNotificationSetting({
            is_enable_low_stock_notification: this.state.isEnableLowStockNotification,
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Notification setting updated", "success"))
                this.reloadOrganization();
                window.location.reload(); // force reload when update notification setting updated
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }


    updateAccessCodeConfiguration(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdateOrganizationAccessCodeConfiguration({
            access_code_login_enabled: this.state.accessCodeLoginEnabled,
            access_code_mode: this.state.accessCodeMode
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("access code configuration updated", "success"))
                this.reloadOrganization();
                window.location.reload(); // force reload when update notification setting updated
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        })
    }



    updateJobIdStatus(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.isUpdateJobIdStatus) {
            return;
        }
        this.setState({
            isUpdateJobIdStatus: true
        })
        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdateJobIdStatus({ job_id_status: this.state.jobIdStatus }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Job ID status updated", "success"))
                this.reloadOrganization();
                window.location.reload(); // force reload when update Job id status
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(() => {
            this.setState({
                isUpdateJobIdStatus: false
            })
        })
    }

    updateTimezone(e: any) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ timezone: e.target.value });
    }

    saveTimezone(e: any) {
        e.preventDefault();
        e.stopPropagation();

        getAdminOrganizationServiceAPI().adminOrganizationServiceUpdateOrganizationTimezone({ timezone: this.state.timezone }).then((resp) => {
            return resp.data
        }).then((resp) => {
            if (resp.success) {
                this.props.pushFlashMessage(new Message("Timezone updated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(resp.error_message ?? "Unknown error", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Unknown error", "error"))
        })
    }

    selectCountry(code: ICountryCodeItem) {
        this.setState({ countryCode: "+" + code.countryCodes[0] });
    }

    render() {
        const availableCountrieOptions = codes.map((code, idx) => {
            return <a
                className={"dropdown-item " + (`+${code.countryCodes[0]}` === this.state.countryCode ? " highlighted" : "")}
                href={"#"} key={idx} onClick={(e) => {
                    e.preventDefault();
                    this.selectCountry(code)
                }}>{code.isoCode2} - {code.country} - +{code.countryCodes[0]}</a>
        });

        const currentCountryCode = codes.find((code) => {
            return code.countryCodes[0] === this.state.countryCode.substring(1)
        });


          

        const adminRows = this.state.admins.filter((admin) => {
            if (this.state.isShowDisabledAdmin) {
                return true
            }
            return admin.is_activated
        }).map((admin,index) => {
            return <AdminRow pushFlashMessage={this.props.pushFlashMessage.bind(this)} admin={admin}
                key={index}
                reload={this.reloadListOfAdmins.bind(this)}
                openEditAdminRolesModal={this.openEditAdminRolesModal.bind(this)} />
        })


        let hidInfo = "Disabled";

        if (this.state.organization?.facility_code_length ?? 0 > 0) {
            hidInfo = "Enabled"

            if (this.state.organization?.default_wiegand_facility_code) {
                hidInfo += ` - Facility Code: ${this.state.organization?.default_wiegand_facility_code}`
            }
        }

        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Organization - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <OriganzationCloneModal isOpen={this.state.isOriganzationCloneModalOpen}
                title={"Organization Clone"} 
                content={"Are you sure you want to clone the organization?"}
                closeOrgainizationCloneModal={() => {
                    this.setState({ isOriganzationCloneModalOpen: false })
                }}
                reload={this.reloadListOfAdmins.bind(this)} />
            <AddAdminModal isOpen={this.state.isAddAdminModalOpen}
                closeAddAdminModal={this.closeAddAdminModal.bind(this)}
                reloadCompanyDetail={this.reloadListOfAdmins.bind(this)} />
            <EditAdminRolesModal isOpen={this.state.isEditAdminRolesModalOpen}
                closeAddAdminModal={this.closeEditAdminRolesModal.bind(this)}
                reloadCompanyDetail={this.reloadListOfAdmins.bind(this)}
                admin={this.state.selectedAdmin} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <div className="row">
                                        <h4 className="card-title">Organization Information</h4>
                                        &nbsp;&nbsp;&nbsp;
                                        {
                                            this.props.authentication.userType === UserType.ADMIN && 
                                            <span className="btn btn-primary btn-sm"
                                            onClick={this.openOriganzationModal.bind(this)}>
                                            Organization Clone
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form>
                                    <div className=" row align-items-center">
                                        <div className="form-group col-sm-12">

                                            <label htmlFor="companyName">Organization Name:</label>

                                            <input type="text" className="form-control" id="companyName"
                                                disabled
                                                value={this.state.organization?.name} />
                                        </div>
                                    </div>
                                </form>

                                <form onSubmit={this.saveTimezone.bind(this)}>
                                    <div className=" row align-items-center">
                                        <div className={"form-group col-sm-12"}>
                                            <label>Timezone</label>
                                            <input type={"text"} className="form-control" value={this.state.timezone} onChange={this.updateTimezone.bind(this)} />
                                        </div>

                                    </div>
                                    <input type={"submit"} className={"btn btn-primary"} value={"Update timezone"} />
                                </form>
                            </div>
                        </div>

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Organization Logo</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">
                                    <div className=" row align-items-center">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="companyLogo">Select image:</label>
                                            <input type="file" id="companyLogo" name="companyLogo" accept="image/*"
                                                onChange={this.handleLogoUpload.bind(this)} />
                                        </div>
                                        <div className="form-group col-sm-8">
                                            <button onClick={this.saveLogo.bind(this)}
                                                className="btn btn-primary">Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Organization Contact Details</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">
                                    <div className=" row align-items-center">
                                        <div className="input-group col-sm-4">
                                            <div className={"input-group-prepend"}>
                                                <button className="btn btn-outline-secondary dropdown-toggle"
                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    {this.state.countryCode}
                                                </button>

                                                <div className={"dropdown-menu"}>
                                                    <div role="separator" className="dropdown-divider"></div>
                                                    {availableCountrieOptions}
                                                </div>
                                                <input className={"form-control"} value={this.state.phoneNumber}
                                                    onChange={this.setPhoneNumber.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-8">
                                            <button onClick={this.savePhoneNumber.bind(this)}
                                                className="btn btn-primary">Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Job ID Settings</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">
                                    <div className=" row align-items-center">
                                        <div className="form-group col-sm-12">
                                            <select className="form-control"
                                                value={this.state.jobIdStatus}
                                                onChange={this.handleJobIdStatusChange.bind(this)}>
                                                <option value={Ppeemployerv1JobIDStatus.Disabled}>Disabled - No Job ID</option>
                                                <option value={Ppeemployerv1JobIDStatus.Enabled}>Enabled - Allow Job ID input</option>
                                                <option value={Ppeemployerv1JobIDStatus.Enforced}>Enforced - Require Job ID input</option>
                                                <option value={Ppeemployerv1JobIDStatus.StrictlyEnforced}>Strictly Enforced - Require Job ID from a preconfigured jobs</option>
                                            </select>
                                        </div>
                                    </div>
                                    <BusyIndicator busy={this.state.isUpdateJobIdStatus} busyIndicator={<LoadingSpanner />}>
                                        <button className="btn btn-primary mr-2"
                                            onClick={this.updateJobIdStatus.bind(this)}>Submit
                                        </button>
                                    </BusyIndicator>
                                </form>
                            </div>
                        </div>

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">PPE Pickup Methods</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">
                                    <div className=" row align-items-center">
                                        <div className="form-group col-sm-12">
                                            <label>Mobile APP:</label>
                                            <select className="form-control"
                                                value={this.state.isMobileAppAllowed.toString()}
                                                onChange={this.handleIsMobileAppAllowedChange.bind(this)}>
                                                <option value={"true"}>Yes</option>
                                                <option value={"false"}>No</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Mifare Card:</label>
                                            <select className="form-control"
                                                value={this.state.isMifareCardAllowed.toString()}
                                                onChange={this.handleIsMifareCardAllowedChange.bind(this)}>
                                                <option value={"true"}>Yes</option>
                                                <option value={"false"}>No</option>
                                            </select>
                                        </div>

                                        <div className={"form-group col-sm-12"}>
                                            <label>HID Card (Wiegand):</label>
                                            <input type={"text"} className={"form-control"} readOnly={true}
                                                value={hidInfo} />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary mr-2"
                                        onClick={this.updatePickUpMethods.bind(this)}>Submit
                                    </button>
                                </form>
                            </div>
                        </div>


                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Notification Settings</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">
                                    <div className="row align-items-center">
                                        <div className="form-group col-sm-12">
                                            <label>Enable Low Stock Notification?</label>
                                            <select className="form-control"
                                                value={this.state.isEnableLowStockNotification.toString()}
                                                onChange={this.handleIsEnableLowStockNotificationChange.bind(this)}>
                                                <option value={"true"}>Yes</option>
                                                <option value={"false"}>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary mr-2"
                                        onClick={this.updateNotificationSetting.bind(this)}>Submit
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Access Code Configuration</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form encType="multipart/form-data">

                                    <div className="row align-items-center">
                                        <div className="form-group col-sm-12">
                                            <label>Enable Access Code Login?</label>
                                            <select className="form-control"
                                                value={this.state.accessCodeLoginEnabled.toString()}
                                                onChange={this.handleAccessCodeLoginEnabledChange.bind(this)}>
                                                <option value={"true"}>Yes</option>
                                                <option value={"false"}>No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className=" row align-items-center">
                                        <div className="form-group col-sm-12">
                                            <label>Access Code Mode</label>
                                            <select className="form-control"
                                                value={this.state.accessCodeMode}
                                                onChange={this.handleAccessCodeModeChange.bind(this)}>
                                                <option value={Ppeemployerv1AccessCodeMode.Numeric}>Numeric</option>
                                                <option value={Ppeemployerv1AccessCodeMode.Alphanumeric}>Alphanumeric</option>
                                            </select>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary mr-2"
                                        onClick={this.updateAccessCodeConfiguration.bind(this)}>Submit
                                    </button>
                                </form>
                            </div>
                        </div>


                        <ServiceNowCard servicenow={this.state.organization?.servicenow ?? null} />

                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Administrators</h4>
                                </div>
                                <div>
                                    <label>Show Disabled Admins
                                        &nbsp;&nbsp;
                                        <input type="checkbox" checked={this.state.isShowDisabledAdmin}
                                            onChange={this.toggleShowDisabledAdmin.bind(this)} />
                                    </label>
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                    <span className="btn btn-primary btn-sm"
                                        onClick={this.openAddAdminModal.bind(this)}>
                                        Add Role
                                    </span>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div id="table" className="table-editable">
                                    <table
                                        className="table table-bordered table-responsive-md table-striped text-center"
                                        id="user_table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Creation Time</th>
                                                <th>Status</th>
                                                <th>Role</th>
                                                <th>Operation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export const
    CompanyProfilePage = withGlobalData(_CompanyProfilePage)

